@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

html {
  box-sizing: border-box;
  font-size: 16px;
}

::selection {
  background: #F5CB6E;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #80B2CD;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #80B2CD;
}

*, *:before, *:after {
  box-sizing: inherit;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
